.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
    background-color: aliceblue;
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    background-color: white;
}